<template lang="pug">
  .vue-pouvoir-knight
    copie-pouvoir-en-cours(v-if="solved" names="knight")
    div(v-else)
      img.w-100.p-absolute.bg(src="@/assets/pouvoirs/knight/fond.jpg" srcset="@/assets/pouvoirs/knight/fond@2x.jpg 2x")
      .text-block.titre {{$t('pouvoirs.knight.titre')}}
      menu-button
      button(v-for="i in 5"
        :class="[`fleche-haut-${i}`, { selected: value[i - 1] === 'h' }]"
        @click="select(i - 1, 'h')")
        img(src="@/assets/pouvoirs/knight/fleche_haut.png" srcset="@/assets/pouvoirs/knight/fleche_haut@2x.png 2x")
      button(v-for="i in 5"
        :class="[`fleche-bas-${i}`, { selected: value[i - 1] === 'b' }]"
        @click="select(i - 1, 'b')")
        img(src="@/assets/pouvoirs/knight/fleche_bas.png" srcset="@/assets/pouvoirs/knight/fleche_bas@2x.png 2x")
      button.validate(@click="validate") {{ $t('pouvoirs.knight.valider') }}
</template>

<script>
import MenuButton from '@/components/MenuButton.vue'
import CopiePouvoirEnCours from '@/components/CopiePouvoirEnCours.vue'

export default {
  name: 'VuePouvoirKnight',

  components: {
    MenuButton, CopiePouvoirEnCours
  },

  data () {
    return {
      value: '-----',
      solved: false
    }
  },

  methods: {
    select(index, value) {
      this.$sound('click')
      const v = this.value.split('')
      v[index] = value
      this.value = v.join('')
    },

    validate() {
      this.solved = this.value === 'hbbbh'
      this.$sound(this.solved ? 'ok' : 'error')
    }
  }
}
</script>

<style lang="less">
.vue-pouvoir-knight {
  .text-block.titre {
    position: absolute;
    left: 50%;
    top: 50px;
    width: 800px;
    transform: translateX(-50%);
  }

  #menu-button {
    right: 20px;
  }

  button {
    position: absolute;
    padding: 8px;
    transform: translateZ(0);
    transition: transform 100ms;

    &:active,
    &.selected:active {
      transform: scale(1.1) translateZ(0);
    }

    &.selected {
      background: #a0f24e;
    }

    &.validate {
      left: 1537px;
      top: 933px;
      padding-left: 1em;
      padding-right: 1em;
    }

    &.fleche-haut-1 { left: 215px; top: 261px; }
    &.fleche-haut-2 { left: 494px; top: 512px; }
    &.fleche-haut-3 { left: 773px; top: 230px; }
    &.fleche-haut-4 { left: 1098px; top: 471px; }
    &.fleche-haut-5 { left: 1478px; top: 162px; }

    &.fleche-bas-1 { left: 215px; top: 600px; }
    &.fleche-bas-2 { left: 494px; top: 902px; }
    &.fleche-bas-3 { left: 773px; top: 646px; }
    &.fleche-bas-4 { left: 1098px; top: 948px; }
    &.fleche-bas-5 { left: 1478px; top: 705px; }
  }
}
</style>
